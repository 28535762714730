require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// External imports
import "bootstrap";

import { menuButton } from "../components/Navbar";
import { searchButton } from "../components/Search";
import { profileButton } from "../components/Profile";
import { filter } from "../components/Filter";
import { PageTurn } from "../components/PageTurn";
import { richardButton } from "../components/RichardButton";
import { editionScroll } from "../components/Edition";
import { cutHeadings } from "../components/CutHeadings";
import { showCheckboxes, insertProviders, toggleFilters } from "../pages/directory";
import { editionsSearch } from "../pages/edition";
import { previuosEvents } from "../pages/events";
import { bookmarkArticle  } from "../pages/the_cut";
import { imgPopUp  } from "../pages/article_image_pop_up";
import { stripePay  } from "../pages/stripe";
import { stockSearch  } from "../pages/stock_search";
import { excelParser } from "../pages/excel_parser";

document.addEventListener('turbolinks:load', () => {
  // Call your functions here, e.g:
  excelParser();
  stockSearch();
  stripePay();
  previuosEvents();
  editionsSearch();
  editionScroll();
  menuButton();
  richardButton();
  profileButton();
  PageTurn();
  filter();
  searchButton();
  cutHeadings();
  showCheckboxes("type-select", "type-check");
  showCheckboxes("sector-select", "sector-check");
  showCheckboxes("geography-select", "geography-check");
  showCheckboxes("asset-select", "asset-check");
  toggleFilters();
  imgPopUp();
  bookmarkArticle();
  insertProviders();

  const tooltips = document.querySelectorAll("[data-toggle=\"collapse\"]")
  tooltips.forEach((tooltip) => {
    tooltip.tooltip()
  })

  var myModal = new bootstrap.Modal(document.getElementById('summary-modal'), {})
  myModal.toggle()
});

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import "controllers"
